import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkoutForm";
import { useParams } from "react-router-dom";
import axios from "axios";
import {getPaymentConfig, getPaymentIntentSecret} from "../../axios/paymentService";
import {message, Spin} from "antd";
import './payment.css';

interface Prop {

}

const Payment: React.FC<Prop> = () => {
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const { amount } = useParams();
    const [amountSGD, setAmountSGD] = useState<number>(0.00);
    const [discount, setDiscount] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        loadData();
        setLoading(false);
    }, []);

    const loadData = async () => {
        try {
            if (!amount || isNaN(Number(amount)) || parseInt(amount) <= 0){
                message.error("Invalid amount");
                return;
            }
            const response = await getPaymentConfig();
            const publishableKey = response;

            // @ts-ignore
            setStripePromise(loadStripe(publishableKey));
            const response2 = await getPaymentIntentSecret(parseFloat(amount));
            setAmountSGD(response2.amount_in_sgd);
            setDiscount(response2.discount);
            setClientSecret(response2.secret);
        } catch (error) {
            console.error(error);
        }

    }


    return (
        <Spin spinning={loading}>
        <div className={"payment-page"}>
            <div className={"payment-box"}>
                <p>Top Up Credit USD${amount}</p>
                {
                    discount !== 1 ? (
                        <p> You have a discount rate of {(1 - discount) * 100}%</p>
                    ) : null

                }
                <p>Your Total Amount in SGD: S${amountSGD}</p>
                <p style={{color:"#bd2222"}}>!!!use PAYNOW only, do not use card payment!!!</p>
                {clientSecret && stripePromise && (
                    <Elements
                        stripe={stripePromise}
                        options={{clientSecret: clientSecret}}
                    >
                        <CheckoutForm amount={amount ? amount : "0"}/>
                    </Elements>
                )}
            </div>
        </div>
        </Spin>

    );
}

export default Payment;