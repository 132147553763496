import {getData} from "./axios";

export interface usage {
    id:number;
    created_at:number;
    token_name:string;
    model_name:string;
    input_tokens:number;
    output_tokens:number;
    price:number;
}

interface ListUsageResponse {
    data: usage[]
}
export const ListUsage = async (page: number): Promise<ListUsageResponse> => {
    const response: any = await getData(`/api/v1/usage/list?page=${page-1}`)
    return response
}