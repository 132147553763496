import axios, { AxiosError } from 'axios';
import {getData} from "./axios";


export interface GetModelsResponse {
    models: ChatModel[]

}

export interface ChatModel {
    id: string;
    name: string;
    value: string;
    price_input: number;
    price_output: number;
}

export const GetModels = async (): Promise<GetModelsResponse> => {
    try {
        const response: any = await getData("/api/v1/model/list")
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
}