import {getData, postData} from "./axios";
import axios, {AxiosError} from "axios";

export const getPaymentConfig = async (): Promise<string> => {
    try {

        const response: any = await getData("/api/v1/payment/configs")
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};

interface PaymentIntentResponse {
    intent_id: string;
    secret:   string;
    discount: number;
    amount_in_sgd: number;
}

export const getPaymentIntentSecret = async (amount:number): Promise<PaymentIntentResponse> => {
    try {
        let body = {
            amount: amount
        }
        const response: any = await postData("/api/v1/payment/makePayment",body)
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};

export interface FullFillPaymentRequest {
    success: boolean;
    secret: string;
    intent_id: string;
}
export const sendFullFillPaymentRequest = async (body: FullFillPaymentRequest): Promise<string> => {
    try {
        const response: any = await postData("/api/v1/payment/fullFillPayment",body)
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};
const FX_RATE_URL = "https://v6.exchangerate-api.com/v6/f8733b799d7f7efc70e0620a/pair/USD/SGD/"
interface FXRateResponse {
    reuslt: string
    documentation: string
    terms_of_use: string
    time_last_update_unix: number
    time_last_update_utc: string
    time_next_update_unix: number
    time_next_update_utc: string
    base_code: string
    target_code: string
    conversion_rate: number
    conversion_result: number
}
export const getfxRateUSDToSGD = async (price: number): Promise<number> => {
    try {
        const response: any = await axios.get(FX_RATE_URL + price)
        return response.data?.conversion_result
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};
