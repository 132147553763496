import React, {useEffect} from "react";
import {Button, Spin, Statistic, Table, TableProps, Tag, Pagination, Modal, InputNumber, message} from "antd";
import './profile.css'
import { GetAccountDetail} from "../../axios/userService";
import {ListUsage, usage} from "../../axios/usageService";
import {useNavigate} from "react-router-dom";
import {getfxRateUSDToSGD} from "../../axios/paymentService";


interface Props {

}


const Profile:React.FC<Props> = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [usageLoading, setUsageLoading] = React.useState<boolean>(false);
    const [usageTotalPage, setUsageTotalPage] = React.useState<number>(1);
    const [isTopUpModalOpen, setIsTopUpModalOpen] = React.useState<boolean>(false);
    const [topUpAmount, setTopUpAmount] = React.useState<number>(10);
    const [topUpAmountInSGD, setTopUpAmountInSGD] = React.useState<number>(0);
    const [loadFXloading, setLoadFXLoading] = React.useState<boolean>(false);

    useEffect(() => {
        loadData();
    }, []);

    const [balance, setBalance] = React.useState<number>(0);
    const [usage, setUsage] = React.useState<usage[]>([]);
    const [usagePage, setUsagePage] = React.useState<number>(1);
    const  navigate  = useNavigate();

    function timestampToDateTime(timestamp:number) {
        const date = new Date(timestamp * 1000); // multiply by 1000 to convert seconds to milliseconds

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }


    const columns: TableProps<usage>['columns'] = [
        {
            title: 'Time',
            dataIndex: 'created_at',
            key: 'id',
            // get the time from the created_at timestamp field
            render: (text) =>
                <div>{timestampToDateTime(text)}</div>
            ,
        },
        {
            title: 'Model Name',
            dataIndex: 'model_name',
            key: 'id',
            render: (text) =>{
                let color = text.includes("gpt") ? 'green' : 'geekblue';
                return <Tag color={color} key={text}> {text} </Tag>
            }
        },
        {
            title: 'Input Tokens',
            dataIndex: 'input_tokens',
            key: 'id',
        },
        {
            title: 'Output Tokens',
            dataIndex: 'output_tokens',
            key: 'id',
        },
        {
            title: 'Total Price',
            dataIndex: 'price',
            key: 'id',
            render: (text) => {return <div>{`$${text}`}</div>}
        },
    ];

    const loadData = async () => {
        setLoading(true);
        let accResult = await GetAccountDetail();
        if (accResult){
            setBalance(accResult.balance);
        }
        setTopUpAmountInSGD(await getSGDAmount(topUpAmount));
        await onUsagePageChange(1)
        setLoading(false);
    }

    const getSGDAmount = async (price: number): Promise<number> => {
        setLoadFXLoading(true)
        try {
            let sgd = await getfxRateUSDToSGD(price);
            return sgd;
        } catch (error) {
            message.error("Failed to get exchange rate");
            return -1;
        } finally {
            setLoadFXLoading(false)

        }

    }

    const onUsagePageChange = async (page:number) => {
        setUsagePage(page)

        setUsageLoading(true);
        let usageResult = await ListUsage(page);
        if (usageResult){
            setUsage(usageResult.data);
            if (!(usageResult.data?.length < 10) && page === usageTotalPage) {
                setUsageTotalPage(usageTotalPage + 1)
            }
        }
        setUsageLoading(false);
    }

    const handleConfirmTopUp = async () => {
        setIsTopUpModalOpen(false);
        console.log("Top up amount: ", topUpAmount);
        // parse th efloat to 2 decimal places
        navigate("/payment/"+topUpAmount);
    }
    const handleCancelTopUp = async () => {
        setIsTopUpModalOpen(false);
    }
    const handleTopUpAmountChange = async (value:number | string | null) => {
        setTopUpAmount(value as number);
        setTopUpAmountInSGD(await getSGDAmount(value as number));
    }

    return (

        <Spin spinning={loading}>
            <Modal title="Amount to top up In USD" open={isTopUpModalOpen} onOk={handleConfirmTopUp} onCancel={handleCancelTopUp} okText={"Proceed to Pay"}>
                <Spin spinning={loadFXloading}>
                <InputNumber min={10} max={100} addonBefore="$" defaultValue={10} onChange={handleTopUpAmountChange} />
                <p>Your SGD Amount is : ${topUpAmountInSGD}</p>
                </Spin>
            </Modal>
            <div className={"page-container"}>
                <div style={{height: "5%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{width: "max-content"}}>
                        <div style={{fontSize: "1em", fontWeight: "bold"}}>Profile</div>
                    </div>

                </div>

                <div className={"section-container"}>
                    <div style={{
                        backgroundColor: "#ffffff",
                        width: "max-content",
                        padding: "2%",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    }}
                    >
                        <Statistic title="Credit Balance (USD)" value={balance} precision={2}/>
                        <Button style={{marginTop: 16}} type="primary" onClick={()=>{
                            setIsTopUpModalOpen(true)
                        }}>
                            Recharge
                        </Button>
                    </div>
                </div>

                <div style={{height: "5%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{width: "max-content"}}>
                        <div style={{fontSize: "1em", fontWeight: "bold"}}>Credit Usage History</div>
                    </div>

                </div>
                <div className={"section-container"}>
                    <div style={{
                        backgroundColor: "#ffffff",
                        width: "100%",
                        padding: "2%",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        height: "50vh",
                        // overflowX: "auto"

                    }}>
                    <Table
                        columns={columns}
                        pagination={false}
                        dataSource={usage}
                        loading={usageLoading}
                        style={{width: "100%"}}
                        scroll={{ y: 180, x: 800 }}
                    />
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "2%",
                            height: "max-content"

                        }}>
                            <Pagination simple  defaultCurrent={1} current={usagePage} total={usageTotalPage*10} onChange={onUsagePageChange} pageSize={10} pageSizeOptions={[10]} />
                        </div>

                    </div>
                </div>
            </div>
        </Spin>
    );
}

export default Profile;