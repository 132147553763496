// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, {AxiosError} from "axios";
import {getData, postData} from "./axios";

type AuthResponse = {
    token?: string;
    expiry?: string;
    user_id?: string;
    api_key?: string;
};

export interface RegisterProps {
    email: string;
    password: string;
    captcha?: string;
    invite_code?: string;
}
export const loginApi = async (email: string | undefined, password: string | undefined): Promise<AuthResponse> => {
    try {
        const data : Object = {
            email:email,
            password:password
        }
        const response: any = await postData("api/v1/user/login",data)
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};


export const registerApi = async (data:RegisterProps): Promise<AuthResponse> => {
    try {
        const response: any = await postData("/api/v1/user/signup",data)

        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};

export const SendCaptcha = async (email: string): Promise<null> => {
    const response: any = await postData("/api/v1/user/sendCaptcha", {
        email: email
    })
    return response
};
interface GetAccountDetailProps {
    balance : number
}

export const GetAccountDetail = async (): Promise<GetAccountDetailProps> => {
    const response: any = await getData("/api/v1/user/balance")
    return response
}

