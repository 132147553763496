import React, { useEffect, useState } from 'react';
import {chat, createChat, getAllChats, getChatList} from '../../../axios/chatService';
import {Button, Select, Card, Spin} from 'antd';
import './chatHistory.css';
import {useNavigate} from "react-router-dom";
const { Option } = Select;
const ChatHistory: React.FC = () => {
    const [data, setData] = useState<Array<chat>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [historyPage, setHistoryPage] = useState<number>(1);
    const [isLastPage, setIsLastPage] = useState<boolean>(false);
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const result = await getChatList(historyPage);
        if (result == null || result.length < 10) {
            setIsLastPage(true);
        }
        if(result !== null){
            setData([...data, ...result]);
            setHistoryPage(historyPage + 1);
        }

        setLoading(false);
    };

    const formatTime = (time: string) => {
        const date = new Date(time);
        return date.toLocaleString();
    };


    return (
        <div className={"history-container"}>
            <Spin spinning={loading} delay={500}>
            {data?.map((item, index) => (
                <Card
                    key={index}
                    size="small"
                    title={<p style={{fontSize:"0.8em"}}>{formatTime(item.created_at)}</p>}
                    extra={<a onClick={() => {
                        // window.location.href = `/chat/${item.id}`;
                        navigate(`/chat/${item.id}`)

                    }
                    }>Continue</a>}
                    style={{
                        width: '96%',
                        margin: '2%',
                        backgroundColor: '#e6f0ff',
                        borderRadius: '10px',
                        boxShadow: '0 1px 2px 0 #dedede',
                }}
                >
                    {/*<p className="truncate-overflow">{item.title}</p>*/}
                    <p className="truncate-overflow">{item.title}</p>
                </Card>
            ))}
            <Button
                onClick={fetchData}
                style={{width:"96%", margin:"2%"}}
                disabled={isLastPage}>
                {isLastPage ? "No More Chats" : "Load More"}
            </Button>
            </Spin>
        </div>
    );
};

export default ChatHistory;
