import {
    LockOutlined,
    MailOutlined
} from '@ant-design/icons';
import {
    LoginForm,
    ProFormCheckbox,
    ProFormText
} from '@ant-design/pro-components';
import { Space, Tabs, message, theme } from 'antd';
import type { CSSProperties } from 'react';
import {loginApi} from "../../axios/userService";
import {useNavigate} from "react-router-dom";

interface LoginProps {
    email: string;
    password: string;
}

export default () => {
    const { token } = theme.useToken();
    const container: CSSProperties = {
        display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            overflow: 'auto',
            backgroundImage:
        "url('https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/V-_oS6r-i7wAAAAAAAAAAAAAFl94AQBr')",
            backgroundSize: '100% 100%',
    };
    const navigate = useNavigate();

    const onFinish = async (values: LoginProps) => {
        let result = await loginApi(values.email, values.password);
        if (result) {
            //console.log(result)
            localStorage.setItem("user", JSON.stringify(result));
            message.success('Login Success, redirecting to home page');
            navigate("/")
        }
    }

    return (
            <div style={{...container, backgroundColor: token.colorBgContainer }}>
                <LoginForm
                    title="NOT SO CHAT"
                    subTitle="Cross-Platform Chatbot System"
                    submitter={{
                        searchConfig: {
                            submitText: "Login",
                        },
                    }}
                    onFinish={async (values : LoginProps) => {
                        //console.log('Success:', values);
                        await onFinish(values);
                    }}
                >


                            <ProFormText
                                name="email"
                                fieldProps={{
                                    size: 'large',
                                    prefix: <MailOutlined className={'prefixIcon'} />,
                                }}
                                placeholder={'email'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please key in email address',
                                    },
                                ]}

                            />
                            <ProFormText.Password
                                name="password"
                                fieldProps={{
                                    size: 'large',
                                    prefix: <LockOutlined className={'prefixIcon'} />,
                                    strengthText:
                                        'Password should contain numbers, letters and special characters, at least 8 characters long.',
                                    statusRender: (value) => {
                                        const getStatus = () => {
                                            if (value && value.length > 12) {
                                                return 'ok';
                                            }
                                            if (value && value.length > 6) {
                                                return 'pass';
                                            }
                                            return 'poor';
                                        };
                                        const status = getStatus();
                                        if (status === 'pass') {
                                            return (
                                                <div style={{ color: token.colorWarning }}>
                                                    Strength: Medium
                                                </div>
                                            );
                                        }
                                        if (status === 'ok') {
                                            return (
                                                <div style={{ color: token.colorSuccess }}>
                                                    Strength: Strong
                                                </div>
                                            );
                                        }
                                        return (
                                            <div style={{ color: token.colorError }}>Strength: Weak</div>
                                        );
                                    },
                                }}
                                placeholder={'Password'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please key in password',
                                    },
                                ]}
                            />

                    <div
                        style={{
                            marginBlockEnd: 24,
                        }}
                    >
                        <ProFormCheckbox noStyle name="autoLogin">
                            Remember Me
                        </ProFormCheckbox>
                        <a
                            style={{
                                float: 'right',
                            }}
                            onClick={() => {
                                navigate("/register")
                            }}
                        >
                            Register
                        </a>
                    </div>
                </LoginForm>
            </div>
    );
};