import {getData, postData, postFormData} from "./axios";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { AxiosError } from "axios";
import {message} from "antd";

export type messageItem = {
    type: string,
    text?: string,
    image_url?: {
        url: string
    }
}

export type messageThread = {
    role: string,
    content: Array<messageItem> | string
    content_type?: string,
    file_links?: string[],
}

export type chat = {
    id: string
    user_id: string;
    chat_model: string;
    title: string;
    created_at: string;
    updated_at: string;
    conversations: Array<messageThread>,
};
export const getAllChats = async (pageNumber:number): Promise<Array<chat>> => {
    try {
        const response: any = await getData(`get_chat_history?page=${pageNumber}`)
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};


type createChatResponse = {
    chat_id: string;
}
export const createChat = async (model:string): Promise<chat> => {
    try {
        const response: any = await postData("api/v1/chat/create",{chat_model: model})
        //console.log("asdasdas",response)
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};

export type getChatResponse = {
    chat_id: string,
    conversation: Array<messageThread>
    type: string
}
export const getAChatConversation = async (chatId : string): Promise<getChatResponse> => {
    try {
        const response: any = await postData("get_chat",{chat_id: chatId})
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};


export const sendMessage = async (chatId: string | undefined, messages: messageItem[]): Promise<messageThread> => {
    try {
        const response: any = await postData("send_message",{
            chat_id: chatId,
            message: messages
        })
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};

export interface uploadFileResponse {
    url: string
}
export const uploadFile = async (file: File): Promise<uploadFileResponse> => {
    try {
        let formData = new FormData();
        formData.append('file', file);
        const response: any = await postFormData(formData)
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};


export const updateChat = async (data: chat): Promise<messageThread> => {
    try {
        const response: any = await postData("/api/v1/chat/update",data)
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
}

export const getChat = async (id: string): Promise<chat> => {
    try {
        const response: any = await getData("/api/v1/chat/get?id="+id)
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
}

export const getChatList = async (page :number): Promise<Array<chat>> => {
    try {
        const response: any = await getData("/api/v1/chat/list?page="+page)
        return response
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
}


