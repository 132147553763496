import {
    LockOutlined,
    MailOutlined
} from '@ant-design/icons';
import {
    LoginForm,
    ProFormText
} from '@ant-design/pro-components';
import {Row, Form, message, theme, Col, Button, Input, Spin} from 'antd';
import {CSSProperties, useState} from 'react';
import {registerApi, SendCaptcha, RegisterProps} from "../../axios/userService";
import {useNavigate} from "react-router-dom";



export default () => {
    const { token } = theme.useToken();
    const [ data, setData ] = useState<RegisterProps | null>(null);
    const [ countDown, setCountDown ] = useState<boolean>(false);
    const [ count, setCount ] = useState<number>(60);

    const container: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'auto',
        backgroundImage:
            "url('https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/V-_oS6r-i7wAAAAAAAAAAAAAFl94AQBr')",
        backgroundSize: '100% 100%',
    };
    const navigate = useNavigate();

    const onFinish = async (values: RegisterProps) => {
        values.captcha = values.captcha?.replaceAll(" ", "")
        values.email = values.email?.toLowerCase()
        values.invite_code = values.invite_code?.replaceAll(" ", "")
        let result = await registerApi(values);
        if (result) {
            //console.log(result)
            localStorage.setItem("user", JSON.stringify(result));
            message.success('Register Success, redirecting to home page');
            navigate("/")
        }
    }

    const onGetCaptcha = async () => {
        if(!data || data?.email === ""){
            message.error('Please input your email address!');
        }
        if (!data?.email.includes('@')) {
            message.error('Please input a valid email address!');
            return;
        }
        setCountDown(true);

        try {
            await SendCaptcha(data.email);
            message.success('Captcha sent successfully');
            let count = 60;
            let timer = setInterval(() => {
                count -= 1;
                setCount(count);
                if (count === 0) {
                    setCountDown(false);
                    clearInterval(timer);
                }
            }, 1000);
        } catch (error) {
            message.error('Failed to send captcha');
            setCountDown(false);
        }
    }


    return (
        <div style={{...container, backgroundColor: token.colorBgContainer }}>
            <LoginForm
                title="NOT SO CHAT"
                subTitle="Cross-Platform Chatbot System"
                submitter={{
                    searchConfig: {
                        submitText: "Register",
                    },
                }}
                onFinish={async (values : RegisterProps) => {
                    //console.log('Success:', values);
                    await onFinish(values);
                }}
            >

                <Form.Item
                    name="email"
                    label="Email"
                    tooltip="Your email address"
                    rules={[{ required: true, message: 'Please input your email address!', whitespace: true }]}
                >
                    <Input
                        prefix={<MailOutlined className={'prefixIcon'}/>}
                        onInput={(e) => {
                            let email = e.currentTarget.value;
                            setData({
                                email: email,
                                password: "",
                                captcha: data?.captcha,
                                invite_code: data?.invite_code
                            })
                            }
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="Captcha"
                    extra="We must make sure that your are a human."
                    rules={[{ required: true, message: 'Please input your verification code!' }]}
                >
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item
                                name="captcha"
                                noStyle
                                rules={[{ required: true, message: 'Please input the verification code you got!'}]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Spin spinning={countDown}>
                                {
                                    countDown ? (
                                        <Button type={"default"} style={{ width: '100%' }} disabled>
                                            Get captcha ({count})
                                        </Button>
                                    ) : (
                                        <Button type={"default"} style={{ width: '100%' }} onClick={onGetCaptcha}>
                                            Get captcha
                                        </Button>
                                    )
                                }
                            </Spin>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true, message: 'Please input your password!', whitespace: true }]}
                >
                    <ProFormText.Password
                        name="password"
                        fieldProps={{
                            size: 'large',
                            prefix: <LockOutlined className={'prefixIcon'} />,
                            strengthText:
                                'Password should contain numbers, letters and special characters, at least 8 characters long.',
                            statusRender: (value) => {
                                const getStatus = () => {
                                    if (value && value.length > 12) {
                                        return 'ok';
                                    }
                                    if (value && value.length > 6) {
                                        return 'pass';
                                    }
                                    return 'poor';
                                };
                                const status = getStatus();
                                if (status === 'pass') {
                                    return (
                                        <div style={{ color: token.colorWarning }}>
                                            Strength: Medium
                                        </div>
                                    );
                                }
                                if (status === 'ok') {
                                    return (
                                        <div style={{ color: token.colorSuccess }}>
                                            Strength: Strong
                                        </div>
                                    );
                                }
                                return (
                                    <div style={{ color: token.colorError }}>Strength: Weak</div>
                                );
                            },
                        }}
                        placeholder={'Password'}
                        rules={[
                            {
                                required: true,
                                message: 'Please key in password',
                            },
                        ]}
                    />
                </Form.Item>


                <Form.Item
                    name="invite_code"
                    label="Invitation Code (Optional)"
                    tooltip="Ivitation Code from your friend, not required for registering."
                    rules={[{ required: false}]}
                >
                    <Input
                        placeholder={'Invitation Code (Optional)'}
                    />
                </Form.Item>

                <div
                    style={{
                        marginBlockEnd: 24,
                    }}
                >
                    <a
                        style={{
                            float: 'right',
                            marginBottom: 24,
                        }}
                        onClick={() => {
                            navigate("/login")
                        }}
                    >
                        Login
                    </a>
                </div>
            </LoginForm>
        </div>
    );
};