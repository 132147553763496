
export interface UserPreference {
    model: string;
}

export const getUserPreferenceUtil = (): UserPreference => {
    const userPref = localStorage.getItem('userPref');
    if (userPref) {
        return JSON.parse(userPref);
    }
    return {model: 'gpt-3.5-turbo'};
};

export const setUserPreferenceUtil = (userPref: UserPreference) => {
    localStorage.setItem('userPref', JSON.stringify(userPref));
};