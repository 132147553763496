// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { message } from 'antd';

// const BASE_URL = 'http://127.0.0.1:3000'
// export const CHAT_URL = 'http://127.0.0.1:3555/v1'
// const BASE_URL = 'https://api.notsochat.com'
// export const CHAT_URL = 'https://chat-api.notsochat.com/v1'
// const FILE_UPLOAD_URL = 'https://chat-upload.linyucong.me/upload'


const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://api.notsochat.com' : 'https://preview.notsochat.com';
export const CHAT_URL = process.env.NODE_ENV === 'production' ? 'https://chat-api.notsochat.com/v1' : 'https://preview-chat-api.notsochat.com/v1';
const FILE_UPLOAD_URL = 'https://chat-upload.linyucong.me/upload';
console.log(`API Base URL: ${BASE_URL}`);
const api: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 1000000,
    headers: {
        'Content-Type': 'application/json',
    }
});

interface Response {
    data: any;
    status: number;
    errorMessage: string;
    success: boolean;
}

api.interceptors.request.use(
    async (config) => {
        // add token to the header
        var tokenJson = JSON.parse(localStorage.getItem('user') || '{}');
        if (tokenJson?.token) {
            config.headers.Authorization = `Bearer ${tokenJson.token}`;
        }
        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => {
        // Any status code that lies within the range of 2xx causes this function to trigger
        // Do something with response data
        let responseJson = response.data as Response;
        //console.log(responseJson);
        if(!responseJson.success){
            message.error({
                content: responseJson.errorMessage,
            });
        }
        return Promise.resolve(response.data);
    },
    (error) => {
        //console.log(error);
        message.error({
            content: 'There was an error connecting to the server, please try again later.',
        });

        if (error.response?.status === 401 || error.response?.status === 403) {
            message.error({
                content: 'Your session has expired, please log in again.',
            });
          localStorage.removeItem('user');
          window.location.href = '/login';
        }

        return Promise.resolve(error);

    }
);

// GET request
export const getData = async (endpoint: string): Promise<AxiosResponse> => {
    try {
        const response: AxiosResponse = await api.get(endpoint);
        return response.data;
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};

// POST request
export const postData = async (endpoint: string, data: object): Promise<AxiosResponse> => {
    try {
        let response: AxiosResponse;
        response = await api.post(endpoint, data);
        return response.data;
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};

export const postFormData = async (data: FormData): Promise<AxiosResponse> => {
    try {
        let response: AxiosResponse;
        response = await axios.post(FILE_UPLOAD_URL, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }

        });

        return response.data;
    } catch (error: AxiosError | unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
        message.error("Failed to upload file")
        throw error;
    }
};
