import React, {useEffect} from 'react';
import {Button, InputNumber, message, Modal, Result, Spin} from 'antd';
import {FullFillPaymentRequest, sendFullFillPaymentRequest} from "../../axios/paymentService";

const PaymentResult: React.FC = () => {
    const [success, setSuccess] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showSupport, setShowSupport] = React.useState<boolean>(false);
    const [text, setText] = React.useState<string>("");

    useEffect(() => {
        process();

    }, []);

    const process = async () => {
        try {
            setLoading(true);
            let queryParams  = new URLSearchParams(window.location.search);
            if (!queryParams.has("redirect_status")) {
                setShowSupport(true);
                return;
            }
            let request: FullFillPaymentRequest = {
                success: queryParams.get("redirect_status") === "succeeded",
                secret: queryParams.get("payment_intent_client_secret") || "",
                intent_id: queryParams.get("payment_intent") || ""
            }
            setSuccess(queryParams.get("redirect_status") === "succeeded");
            const response = await sendFullFillPaymentRequest(request);
            switch (response) {
                case "succeeded":
                    setText("Your payment has been processed successfully.")
                    setSuccess(true);
                    break;
                case "requires_action":
                    setSuccess(false);
                    setText("Your payment requires additional action. Please contact support for further assistance.")
                    break;
                case "requires_confirmation":
                    setSuccess(false);
                    setText("Your payment requires confirmation. Please contact support for further assistance.")
                    break;
                case "requires_payment_method":
                    setSuccess(false);
                    setText("Your payment failed, Please contact support for further assistance.")
                    break;
                case "processing":
                    setSuccess(false);
                    setText("Your payment is still under processing. Please contact support for further assistance.")
                    break;
                case "canceled":
                    setSuccess(false);
                    setText("Your payment has been canceled. Please contact support for further assistance.")
                    break;
                case "requires_capture":
                    setSuccess(false);
                    setText("Your payment failed, please try again.")
                    break;
                default:
                    break;
            }

        } catch (error) {
            setShowSupport(true);
        } finally {
            setLoading(false);
        }
    }

    const closeModal = () => {
        setShowSupport(false);
    }

    return (
        <Spin spinning={loading}>
            <Modal title="Error processing the payment" open={showSupport} onOk={closeModal} onCancel={closeModal}>
                <p>Please contact support for further assistance.</p>
                <Button onClick={() => {
                    window.location.href = "t.me/wudivf";
                }}>Contact Support</Button>
            </Modal>

            {
                success ? (
                    <Result
                        status="success"
                        title={text}
                        subTitle="Thank you for your payment."
                        extra={[
                            <Button
                                onClick={() => {
                                    window.location.href = "/profile";}
                                }
                            >
                                Go Profile
                            </Button>,
                            <Button
                                onClick={() => {
                                    window.location.href = "/chat";}
                                }
                            >Go Chat</Button>,
                        ]}
                    />
                ) :
                (
                    <Result
                        status="error"
                        title={text}
                        subTitle="Please try again later."
                        extra={[
                            <Button
                                onClick={() => {
                                    window.location.href = "/profile";}
                                }
                            >
                                Go Profile
                            </Button>,
                            <Button
                                onClick={() => {
                                    window.location.href = "/chat";}
                                }
                            >Go Chat</Button>,
                        ]}
                    />
                )
            }

        </Spin>
    );
}

export default PaymentResult;