import React, {useEffect, useRef, useState} from 'react';
import {
    getChatResponse,
    getAChatConversation,
    messageThread,
    uploadFile,
    messageItem, createChat, chat, updateChat, getChat
} from '../../../axios/chatService';
import './chatSide.css';
import {useNavigate, useParams} from "react-router-dom";
import {Alert, Avatar, Button, Input, List, message, Select, Space, Spin, Upload, UploadFile} from "antd";
import MarkdownPreview from "@uiw/react-markdown-preview";
import {UpCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {ChatModel, GetModels} from "../../../axios/modelService";
import OpenAI from "openai";
import {CHAT_URL} from "../../../axios/axios";
import {getUserPreferenceUtil, setUserPreferenceUtil} from "../../../util/userPref";

interface Props {
}

const { TextArea } = Input;
const { Option } = Select;

const ChatSide: React.FC<Props> = () => {
    let [chat, setChat] = useState<chat | null>(null);
    const [conversation, setConversation] = useState<messageThread[]>([{
        role: 'system',
        content: 'Welcome to the NotSoChat! How can I help you today?',
    }]);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const { path_id } = useParams();
    const [userInput, setUserInput] = useState<string>('');
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [textBoxLoading, setTextBoxLoading] = useState<boolean>(false);

    const [files, setFiles] = useState<UploadFile<any>[] >([]);
    const [model, setModel] = useState<string>('gpt-3.5-turbo');
    const [availableModels, setAvailableModels] = useState<ChatModel[]>([]);
    const navigate = useNavigate();
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [currentPathId, setCurrentPathId] = useState<string>('');
    const [isNewChat, setIsNewChat] = useState<boolean>(false)
    const [doNotLoad, setDoNotLoad] = useState<boolean>(false)

    const openai = new OpenAI({
        apiKey: JSON.parse(localStorage.getItem('user') || '{} ').api_key,
        baseURL: CHAT_URL,
        dangerouslyAllowBrowser: true
    })

    const handleScroll = () => {
        if (chatContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
            setIsAtBottom(scrollTop + clientHeight === scrollHeight);
        }
    };

    useEffect(() => {
        if (isAtBottom && chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [conversation, isAtBottom]);

    // load available models
    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (shouldLoad(path_id)) loadData()
    }, [path_id]);

    const shouldLoad = (new_path_id: string | undefined):boolean => {
        if (doNotLoad) {
            setDoNotLoad(false);
            return false;
        }
        console.log(currentPathId, new_path_id)
        if (!new_path_id) return false;
        if(isNewChat && new_path_id !== "") return true;
        return currentPathId !== new_path_id && new_path_id !== "";
    }


    const loadData = async () => {
        setPageLoading(true);

        //set user preference of models
        let preference = getUserPreferenceUtil();
        setModel(preference.model);
        let result = await GetModels();
        if (result?.models.length > 0) {
            setAvailableModels(result.models);
        }
        //console.log("path" + path_id)
        if (path_id) {
            setIsNewChat(false);
                let res = await getChat(path_id);
                if (res) {
                    setChat(res);
                    // override the model if the chat has a model
                    setModel(res.chat_model);
                    setConversation(res.conversations.map((item) => {
                        return {
                            role: item.role,
                            content: item.content_type === 'string' ? item.content : JSON.parse(item.content as string),
                            content_type: item.content_type,
                            file_links: item.file_links,
                        }
                    }));
                }
                setCurrentPathId(path_id);
                if(chatContainerRef.current){
                    // set the scroll to top
                    chatContainerRef.current.scrollTop = 0;
                }


        }else{
            setIsNewChat(true);
        }

        setPageLoading(false);
    }

    // const startChat = async () => {
    //     try {
    //
    //     } catch (e) {
    //         //console.log(e);
    //     }
    // };
    const concatText = (item: messageThread) =>{

        if(typeof item.content === 'string'){
            return item.content
        }

        let result = ''
        for (let i = 0; i < item.content.length; i++) {
            // @ts-ignore
            if(item.content[i].type === 'text'){
                // @ts-ignore
                result += item.content[i].text
            }else { // @ts-ignore
                if (item.content[i].type === 'image'){
                    // @ts-ignore
                    result += `![Image](data:${item.content[i].source?.media_type};base64,${item.content[i].source?.data})`
                }
                // @ts-ignore
                if (item.content[i].type === 'image_url') {
                    // @ts-ignore
                    result += `![Image](${item.content[i].image_url.url})`
                }
            }
        }
        return result
    }

    const generateContent = () => {
        let result: messageItem[] = [];
       if(files.length !== 0){
           result.push({
               type: 'text',
               text: userInput,

           });
           //if there are images
           for (const file of files) {
               result.push({
                     type: 'image_url',
                     image_url: {
                          url: file.url?file.url:'',
                     },
                });
           }
       }else{
              return userInput;
       }
         return result;

    }
    //listen to con

    const updateCloudChat = async (chat: chat) => {
        if (chat) {
            chat.conversations = conversation.map((item) => {
                return {
                    role: item.role,
                    content: item.content_type === 'string' ? item.content : JSON.stringify(item.content),
                    content_type: item.content_type,
                    file_links: item.file_links,
                }
            });
            chat.chat_model = model;
            await updateChat(chat);
        }

    }

// get the title of the conversation async
    const getTitle = async (content: string) => {
        if (chat === null) {
            return;
        }

        const completion = await openai.chat.completions.create({
            messages: [{ role: "user", content: "A user start conversation with following content:\" " +content + "\" . Give a single appropriate title only and do not answer anything else for above user input,also make it within 10 words." }],
            model: "@cf/meta/llama-2-7b-chat-fp16",
        });
        let title = completion.choices[0].message.content as string;
        title = title.replace("Title:", "");
        title = title.replaceAll("\"", "");
        chat.title = title;
        setChat(chat)
        updateCloudChat(chat);

    }

    const onSendMessages = async (e:any) => {
        if (e?.shiftKey) {
            return;
        }
        if(userInput === ''){
            message.error('Please enter a message');
            return;
        }
        e.preventDefault();
        // let newChat = false;
        setTextBoxLoading(true);

        if(chat === null){
            setDoNotLoad(true);
            chat = await createChat(model);
            //console.log(result)
            navigate(`/chat/${chat.id}`);
        }

        conversation.push({
            role: 'user',
            content: generateContent(),
            content_type: files.length > 0 ? 'image':'string',
            file_links: [],
        });


        //delete first message if role is system
        if(conversation[0].role === 'system'){
            conversation.shift();
            // newChat = true;
        }
        setConversation([...conversation])

        try {
            if (conversation?.at(-1)?.role === 'system') {
                conversation.pop();
            }
            // async to do non-blocking update title
            if (chat.title === 'New Chat') {
                // @ts-ignore
                let summary = conversation[0].content_type === 'string' ? conversation[0].content : conversation[0].content[0].text;
                getTitle(summary);
            }

            let tempConv  = conversation.length > 11 ? conversation.slice(-11) : conversation
            let convBody = tempConv.map((item) => {
                return {
                    role: item.role,
                    content: item.content,
                }
            })
            // @ts-ignore
            const stream = await openai.chat.completions.create({
                model: model,
                // @ts-ignore
                messages: convBody,
                stream: true,
            });

            conversation.push({
                role: 'system',
                content: '',
                content_type: 'string',
                file_links: [],
            });
            setConversation([...conversation])



            for await (const chunk of stream) {
                //console.log(chunk.choices[0]?.delta?.content);
                // process.stdout.write(chunk.choices[0]?.delta?.content || "");
                if (chunk.choices[0]?.delta?.content) {
                    //add the content to last item of conversation
                    conversation[conversation.length - 1].content += chunk.choices[0]?.delta?.content;
                    setConversation([...conversation]);
                }

            }


            chat.conversations = conversation
            chat.chat_model = model;
            setChat(chat)
            // async to do non-blocking update converstaion
            updateCloudChat(chat);
            // set the input to empty
            setUserInput('');
        }catch (e) {
            // @ts-ignore
            switch (e.status) {
                case 400:
                    message.error('Bad request to server');
                    break;
                case 401:
                    // @ts-ignore
                    if (e.error?.message?.includes("额度已用尽")){
                        message.error('Credit balance used up, Please top up your account at profile page', 5);
                        break;
                    }else{
                        message.error('Unauthorized request, please contact support');
                        break;
                    }
                case 500:
                    message.error('Internal server error, Please try again later');
                    break;
                default:
                    message.error('There was an error sending the message. Please create new chat');
                    break;
            }
            // @ts-ignore
            console.log(e)

        }
        setTextBoxLoading(false)
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUserInput(e.target.value);
    };

    const checkAllowType = (file: File) => {
        const allowedTypes = [
            'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp',
            //pdf
            "application/pdf",
            //doc
            "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            //txt file
            "text/plain",
        ];
        if (!allowedTypes.includes(file.type)) {
            message.error('Invalid file type');
            return false;
        }
        return true;
    }

    const processUploadImage = async (file: File) => {
        return checkAllowType(file);
    }

    const onFileChange = async (info: any) => {
        //console.log(info);
        if (info.file.status === 'removed') {
            setFiles(info.fileList);
            return;
        }


        message.loading('Uploading file...', 0);
        let result = await uploadFile(info.file.originFileObj);

        appendFileToFiles(info.file.originFileObj, result.url);
        message.destroy();
        message.success(info.file.originFileObj.name+" uploaded successfully")

    }

    const onModelChange = (value: string) => {
        let preference = getUserPreferenceUtil()
        preference.model = value;
        setUserPreferenceUtil(preference)
        setModel(value);
    };

    const handlePaste = async (e: React.ClipboardEvent<HTMLTextAreaElement>) => {

        e.preventDefault();
        let items = e.clipboardData.items
        for (let i = 0; i < items.length; i++) {
            if (items[i].kind === 'file') {
                var pasteFile = items[i].getAsFile()
                if (!pasteFile) {
                    message.error('No file found in clipboard');
                    return;
                }
                if (!checkAllowType(pasteFile)) {
                    message.error('Invalid file type');
                    return;
                }
                message.loading('Uploading file...', 0);
                let result = await uploadFile(pasteFile);
                appendFileToFiles(pasteFile, result.url);
                message.destroy()
                message.success(pasteFile.name+" uploaded successfully")
            }
        }

        // if text is pasted, add to the input
        if (e.clipboardData.getData('text')) {
            setUserInput(userInput + e.clipboardData.getData('text'));
        }
    }
    const appendFileToFiles = (file: File, url:string) => {
        let newFile: UploadFile<any> = {
            // random uuid
            uid: Math.floor(Math.random() * 1000).toString(),
            name: file.name,
            status: 'done',
            url: url,
        };
        setFiles([...files, newFile]);
        if (files.length > 5) {
            message.warning('Max 5 files allowed, Only last 5 files will be kept');
            setFiles(files.slice(-5));
        }
    }

    const handleDrop = async (e: React.DragEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
            if (!checkAllowType(e.dataTransfer.files[i])) {
                message.error('Invalid file type');
                return;
            }
        }
        message.loading('Uploading file...', 0);
        let names = '';

        for (let i = 0; i < e.dataTransfer.files.length; i++) {
            let file = e.dataTransfer.files[i];
            let result = await uploadFile(file);
            appendFileToFiles(file, result.url);
            names += file.name + ', ';
        }
        message.destroy();
        message.success( names+" uploaded successfully")


    }

    return (
        <div style={{ height: '100%' }}>
            <Spin spinning={pageLoading} fullscreen delay={500} />

            <div className='container'>

                <div style={{height: "5%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div style={{width:"max-content"}}>
                        <Select
                            placeholder="Select a model"
                            variant="borderless"
                            style={{ minWidth: 180, textAlign: 'center' }}
                            defaultValue={chat?.chat_model}
                            value={model}
                            onChange={onModelChange}
                            options={[
                                ...availableModels.map((model) => ({ label: `${model.name}`, value: model.value }))
                            ]}
                        />
                    </div>

                </div>
                <div ref={chatContainerRef} className='upper-box' onScroll={handleScroll}>

                        <List
                            itemLayout="horizontal"
                            dataSource={conversation}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar
                                            src={item.role === 'user' ? 'https://api.dicebear.com/8.x/pixel-art/svg' : 'https://api.dicebear.com/8.x/bottts/svg'}/>}
                                        description={
                                        <MarkdownPreview
                                            source={concatText(item)}
                                            className={"chat-box"}
                                            style={{backgroundColor:item.role === 'system'? "#cddfff" : "#c7dec0"}}
                                            wrapperElement={{
                                                "data-color-mode": "light"
                                            }}
                                        />
                                    }/>
                                </List.Item>
                            )}
                        />
                </div>
                <div className='lower-box'>
                    <div>
                        <Spin spinning={textBoxLoading} tip={"AI Thinking..."}>
                            {
                                (model.includes('4')||model.includes('sonnet')) ? (
                                    <Upload
                                        action="https://chat-upload.linyucong.me/upload"
                                        listType="picture-card"
                                        maxCount={5}
                                        beforeUpload={processUploadImage}
                                        onChange={onFileChange}
                                        accept="image/*"
                                        fileList={files}


                                    >
                                        {(files !== undefined && files.length >= 5) ? null :
                                            <button style={{border: 0, background: 'none'}} type="button">
                                                <PlusOutlined/>
                                                <div style={{marginTop: 8}}>Add Image</div>
                                            </button>}


                                    </Upload>
                                ):null
                            }

                            <Space.Compact style={{width: '100%', marginTop: "6px"}} direction={"horizontal"}>
                                <div style={{display: "flex", width: "100%",boxShadow: "0 4px 8px 0 #b0b0b0",borderRadius: '10px'}}>
                                    <TextArea
                                        onChange={onChange}
                                        placeholder="Type your message here"
                                        style={{
                                            fontSize: 16,
                                            borderRadius: '10px 0 0 10px',
                                            backgroundColor: "#fdfdfd",
                                            borderColor: "#fcfcfc",

                                        }}
                                        autoSize={{minRows: 2, maxRows: 4}}
                                        value={userInput}
                                        onPressEnter={onSendMessages}
                                        onDrop={handleDrop}
                                        onPaste={handlePaste}
                                    />
                                    <Button onClick={onSendMessages} size={"large"} type={"primary"} icon={<UpCircleOutlined/>}
                                            style={{height: "100%",borderRadius: '0 10px 10px 0'}}/>
                                </div>
                            </Space.Compact>
                        </Spin>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ChatSide;
