import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider, Navigate, useNavigate} from 'react-router-dom';
import Login from "./pages/login/login";
import Register from "./pages/register/register";
import {Button, Layout, Menu} from "antd";
import Sider from "antd/es/layout/Sider";
import {UploadOutlined, UserOutlined, VideoCameraOutlined} from "@ant-design/icons";
import ChatSide from "./pages/chat/components/chatSide";
import Profile from "./pages/profile/profile";
import NotFoundPage from "./pages/404/404";
import ChatHistory from "./pages/chat/components/chatHistory";
import Payment from "./pages/payment/payment";
import PaymentResult from "./pages/payment/paymentResult";

interface ProtectedRouteProps {
    component: React.ComponentType<any>;
    [key: string]: any;
}

interface AppLayoutProps {
    children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ( { children }   ) => {
    const items = [UserOutlined, VideoCameraOutlined, UploadOutlined, UserOutlined].map(
        (icon, index) => ({
            key: String(index + 1),
            icon: React.createElement(icon),
            label: `nav ${index + 1}`,
        }),
    );
    const  navigate = useNavigate();
    return (
        <Layout className={"box"}>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                zeroWidthTriggerStyle={{top: "2px", backgroundColor: "#f5f5f5", color: "#000000"}}
                style={{height: "100vh"}}
                theme="light"
                width={220}
            >

                {/*<Menu theme="dark" mode="inline" defaultSelectedKeys={['4']} items={items} />*/}
                <div className={"sider-container"}>
                    <div className={"sider-top"} style={{width:"100%",display:"flex", justifyContent:"center", alignItems:"center"}}>

                        <Button
                            type="link"
                            onClick={() => {
                                window.location.href = "/chat"
                            }}
                            style={{width:"100%", textAlign:"center", padding:"10px", marginTop:"5px", height:"max-content"}}
                        >Start New Chat</Button>
                    </div>


                    <div className={"sider-middle"}>
                        {/*<Menu theme="light" mode="inline" defaultSelectedKeys={['4']} items={items} />*/}
                        <ChatHistory/>
                    </div>

                    <div className={"sider-bottom"}>
                        {/*<Menu theme="dark" mode="inline" defaultSelectedKeys={['4']} items={items} />*/}
                        <Menu theme="light" mode="inline" style={{padding:"0"}} >
                            <Menu.Item
                                key="1"
                                icon={<UserOutlined />}
                                onClick={() => {

                                    if (window.location.pathname === "/profile") {
                                        navigate("/")
                                    }else {
                                        navigate("/profile")
                                    }
                                }}
                            >
                                {window.location.pathname === "/profile" ? "Chat" : "Profile"}
                            </Menu.Item>
                        </Menu>
                    </div>

                </div>

            </Sider>
            <Layout>
                {children}
            </Layout>
        </Layout>
    );

}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {
    const isAuthenticated = () => {
        // Check if the token exists in local storage
        const token = localStorage.getItem('user');
        return token !== null;
    };

    return isAuthenticated() ?<Component {...rest} />  : <Navigate to="/login" replace />;
};



const router = createBrowserRouter([
    // redirect / to /chat
    {
        path: '/',
        element: <Navigate to="/chat" />,
    },
    {
        path: '/chat/:path_id?',
        element: (
            <ProtectedRoute
                component={() => (
                    <AppLayout>
                        <ChatSide />
                    </AppLayout>
                )}
            />
        ),
    },
    {
        path: '/profile',
        element: (
            <ProtectedRoute
                component={() => (
                    <AppLayout>
                        <Profile />
                    </AppLayout>
                )}
            />
        ),
    },
    {
        path: '/payment/:amount',
        element: (
            <ProtectedRoute
                component={() => (
                    <AppLayout>
                        <Payment />
                    </AppLayout>
                )}
            />
        ),
    },
    {
        path: '/paymentResult',
        element: (
            <ProtectedRoute
                component={() => (
                    <AppLayout>
                        <PaymentResult />
                    </AppLayout>
                )}
            />
        ),
    },
    {
        path: '/login',
        element: <Login/>,
    },
    {
        path: '/register',
        element: <Register/>,
    },
    {
        path:'*',
        element: <Navigate to="/404" />,
    },
    {
        path: '/404',
        element: <NotFoundPage/>
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();